import type { FC } from "react";
import { useState } from "react";
import PageTable from "../layout/Table";
import { TableHead, TableCell, TableRow, TableBody, Chip } from "@mui/material";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import DoneIcon from "@mui/icons-material/Done";
import AppPage from "../layout/AppPage";
import { useNavigate } from "react-router-dom";
import { usePartners } from "../../hooks/api";

const Partners: FC<{}> = ({}) => {
  const [query, setQuery] = useState<string>("");
  const { data = [], isFetching } = usePartners();
  const navigate = useNavigate();

  return (
    <AppPage avatar={<GroupsOutlinedIcon />} title={"Partners"}>
      <PageTable isLoading={isFetching} onSearch={setQuery}>
        <colgroup>
          <col style={{ width: "500px" }} />
          <col />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>{"Name"}</TableCell>
            <TableCell>{"Status"}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            .filter((row) =>
              row.Name.toLowerCase().includes(query.toLowerCase())
            )
            .map((row) => (
              <TableRow
                hover
                key={row.Id}
                sx={{ cursor: "pointer" }}
                onClick={() => navigate(`/partners/${row.Id}`)}
              >
                <TableCell>{row.Name}</TableCell>
                <TableCell>
                  {row.IsActive ? (
                    <Chip icon={<DoneIcon />} label={"Active"} />
                  ) : (
                    <Chip
                      label={"Inactive"}
                      sx={{
                        backgroundColor: (theme) => theme.palette.divider, // might use theme.palette.background.variant instead
                      }}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </PageTable>
    </AppPage>
  );
};

export default Partners;
