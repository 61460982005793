import type { FC } from "react";
import { useState } from "react";
import PageTable from "../layout/Table";
import { TableHead, TableCell, TableRow, TableBody, Chip } from "@mui/material";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import DoneIcon from "@mui/icons-material/Done";
import AppPage from "../layout/AppPage";
import { usePartners, useUsers } from "../../hooks/api";
import { ZYLINC_PARTNER_ID } from "../../constants";

const Users: FC<{ partnerId: string }> = ({ partnerId }) => {
  const [query, setQuery] = useState<string>("");

  const partners = usePartners().data;

  const { data = [], isFetching } = useUsers(
    partnerId === ZYLINC_PARTNER_ID ? undefined : partnerId
  );

  return (
    <AppPage avatar={<GroupsOutlinedIcon />} title={"Users"}>
      <PageTable isLoading={isFetching} onSearch={setQuery}>
        <colgroup>
          <col style={{ width: "500px" }} />
          <col style={{ width: "500px" }} />
          <col />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>{"Name"}</TableCell>
            <TableCell>{"Email"}</TableCell>
            <TableCell>{"Status"}</TableCell>
            <TableCell>{"Partner"}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            .filter((row) =>
              row.DisplayName.toLowerCase().includes(query.toLowerCase())
            )
            .map((row) => {
              const partner = partners?.find(
                (partner) => partner.Id === row.PartnerId
              );

              return (
                <TableRow key={row.Id} hover sx={{ cursor: "pointer" }}>
                  <TableCell>{row.DisplayName}</TableCell>
                  <TableCell>{row.Email}</TableCell>
                  <TableCell>
                    {row.IsActive ? (
                      <Chip icon={<DoneIcon />} label={"Active"} />
                    ) : (
                      <Chip
                        label={"Inactive"}
                        sx={{
                          backgroundColor: (theme) => theme.palette.divider, // might use theme.palette.background.variant instead
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell>{partner?.Name || ""}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </PageTable>
    </AppPage>
  );
};

export default Users;
