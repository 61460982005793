import {
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Table,
  Skeleton,
} from "@mui/material";

const TableSkeleton = () => {
  const data = [1, 2, 3];

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Skeleton />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row}>
              <TableCell component="th" scope="row">
                <Skeleton />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableSkeleton;
