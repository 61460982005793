import { Address } from "../../types/CreatePartnerRequest";
import { useParams } from "react-router-dom";
import { usePartner } from "../../hooks/api";

import {
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow,
  Typography,
} from "@mui/material";

const AddressInfo = ({ StreetAddress, PostalCode, City, Country }: Address) => (
  <Stack direction={"column"}>
    <Typography fontWeight={700}>{StreetAddress}</Typography>
    <Typography
      fontWeight={700}
    >{`${PostalCode} ${City} (${Country})`}</Typography>
  </Stack>
);

const General = () => {
  const { partnerId = "" } = useParams();
  const { data } = usePartner(partnerId);

  if (!data) return <>Loading...</>;

  return (
    <Table
      sx={{
        width: "initial",
        [`& .${tableCellClasses.root}`]: {
          borderBottom: "none",
        },
      }}
    >
      <TableBody>
        <TableRow>
          <TableCell>
            <Typography>{"Name"}</Typography>
          </TableCell>
          <TableCell>
            <Typography fontWeight={700}>{data.Name}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography>{"Address"}</Typography>
          </TableCell>
          <TableCell>
            <AddressInfo {...data.Address} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography>{"Invoice e-mail address"}</Typography>
          </TableCell>
          <TableCell>
            <Typography fontWeight={700}>{data.InvoiceEmail}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography>{"Invoice attention person"}</Typography>
          </TableCell>
          <TableCell>
            <Typography fontWeight={700}>
              {data.InvoiceAttentionPerson}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography>{"Invoice currency"}</Typography>
          </TableCell>
          <TableCell>
            <Typography fontWeight={700}>{data.InvoiceCurrency}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography>{"Zylinc sales representative"}</Typography>
          </TableCell>
          <TableCell>
            <Typography fontWeight={700}>
              {data.ZylincSalesRepresentative}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default General;
