import type { FC } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageTable from "../layout/Table";
import { TableHead, TableCell, TableRow, TableBody } from "@mui/material";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import AppPage from "../layout/AppPage";
import { Customer } from "../../types/Customer";
import { ZYLINC_PARTNER_ID } from "../../constants";
import { useCustomers, usePartners } from "../../hooks/api";

const Customers: FC<{ partnerId: string }> = ({ partnerId }) => {
  const [query, setQuery] = useState<string>("");
  const navigate = useNavigate();
  const partners = usePartners();

  const { data = [], isFetching } = useCustomers(
    partnerId === ZYLINC_PARTNER_ID ? undefined : partnerId
  );

  const getPartnerName = (customer: Customer) =>
    partners.data?.find(({ Id }) => Id === customer.PartnerId)?.Name || "";

  return (
    <AppPage avatar={<GroupsOutlinedIcon />} title={"Customers"}>
      <PageTable isLoading={isFetching} onSearch={setQuery}>
        <colgroup>
          <col style={{ width: "600px" }} />
          <col />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>{"Name"}</TableCell>
            {!partners.isError && <TableCell>{"Partner"}</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            .filter((row) =>
              row.Name.toLowerCase().includes(query.toLowerCase())
            )
            .map((row) => (
              <TableRow
                key={row.Id}
                hover
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(`/customers/${row.Id}/${row.PartnerId}`)
                }
              >
                <TableCell>{row.Name}</TableCell>
                {!partners.isError && (
                  <TableCell>{getPartnerName(row)}</TableCell>
                )}
              </TableRow>
            ))}
        </TableBody>
      </PageTable>
    </AppPage>
  );
};

export default Customers;
