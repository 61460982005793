import { NavLink, Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { styled } from "@mui/material/styles";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import HandshakeIcon from "@mui/icons-material/Handshake";
import { ZYLINC_PARTNER_ID } from "../../constants";
import { useMyPartner, useMyUser } from "../../hooks/api";

// @ts-ignore
import { ReactComponent as Logo } from "../../assets/LogoIcon.svg";

import {
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  ToggleButton,
  Typography,
} from "@mui/material";

const NavigationPanelCard = styled(Card)(({ theme }) => ({
  flex: "auto",
  margin: 0,
  flexShrink: 0,
  flexGrow: 0,
  width: "200px",
  borderRadius: 0,
  position: "relative",
  zIndex: 5,
  backgroundColor: theme.palette.secondary.main,
  "& .MuiCardContent-root": {
    padding: theme.spacing(2),
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
}));

const NavToggleButton = styled(ToggleButton)(({ theme }) => ({
  border: 0,
  borderRadius: "10px",
  color: theme.palette.text.primary,
  justifyContent: "flex-start",
  "&.Mui-selected": {
    "& .MuiTypography-root": {
      fontWeight: "bold",
    },
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main10,
  },
  padding: theme.spacing(1, 2),
}));

type RouteProps = {
  label: string;
  href: string;
  warning?: boolean;
  exact?: boolean;
  hidden?: boolean;
  external?: boolean;
  icon: React.ReactNode;
};

const NavigationLink: React.FC<RouteProps> = ({ href, label, icon }) => (
  <NavLink to={href}>
    {({ isActive }) => (
      <NavToggleButton fullWidth selected={isActive} value={href} size="small">
        <Stack display="flex" flexDirection="row" gap={1}>
          {icon}
          <Typography sx={{ textTransform: "none" }}>{label}</Typography>
        </Stack>
      </NavToggleButton>
    )}
  </NavLink>
);

const ALL_ROUTES = [
  {
    label: "Partners",
    href: "/partners",
    exact: true,
    icon: <HandshakeIcon />,
  },
  {
    label: "Customers",
    href: "/customers",
    exact: true,
    icon: <AccountCircleOutlinedIcon />,
  },
  {
    label: "Users",
    href: "/users",
    exact: true,
    icon: <GroupsOutlinedIcon />,
  },
];

const getRoutes = (partnerID: string) => {
  if (partnerID === ZYLINC_PARTNER_ID) {
    return ALL_ROUTES;
  }

  return [ALL_ROUTES[1], ALL_ROUTES[2]];
};

const Navigation: React.FC<{
  partnerId: string;
  children: React.ReactNode;
}> = ({ partnerId }) => {
  const { accounts } = useMsal();
  const routes = getRoutes(partnerId);
  const myPartner = useMyPartner().data;
  const myUser = useMyUser().data;

  return (
    <NavigationPanelCard elevation={1}>
      <CardContent>
        <Logo />
        <Stack sx={{ margin: "10px 0" }} direction={"column"} gap={1}>
          {myPartner?.Name && (
            <Typography variant="h6">{myPartner.Name}</Typography>
          )}
          <Divider />
          {accounts && accounts[0].idTokenClaims?.name && (
            <Typography variant="body1">
              {accounts[0].idTokenClaims.name}
            </Typography>
          )}
          {accounts && accounts[0].idTokenClaims?.emails && (
            <Typography variant="body2">
              {accounts[0].idTokenClaims.emails}
            </Typography>
          )}
          {myUser?.Role && (
            <Typography variant="body2">{myUser.Role}</Typography>
          )}
        </Stack>
        <Divider />
        <Stack direction="column" mt={3} mb={3} gap={2} alignItems="stretch">
          {routes.map((route, idx) => (
            <NavigationLink {...route} key={idx} />
          ))}
        </Stack>
        <Divider />
        <Stack direction="column" flexGrow={1} justifyContent="flex-end">
          <Button
            variant="outlined"
            component={Link}
            sx={{
              borderRadius: "100px",
              textTransform: "none",
              color: (theme) => theme.palette.primary.main,
            }}
            startIcon={<LogoutOutlinedIcon />}
            to="/logout"
          >
            Log out
          </Button>
        </Stack>
      </CardContent>
    </NavigationPanelCard>
  );
};

export default Navigation;
