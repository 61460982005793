import { ThemeOptions, createTheme } from "@mui/material/styles";
// https://next.material-ui.com/components/typography/#install-with-npm
import "@fontsource/nunito-sans/300.css";
import "@fontsource/nunito-sans/400.css";
import "@fontsource/nunito-sans/600.css";
import "@fontsource/nunito-sans/700.css";

declare module "@mui/material/styles" {
  // add custom params for primary/secondary colors here

  // when used in styled():
  interface PaletteColor {
    main: string;
    main10?: string;
    main80?: string;
  }
  // when used in palette:
  interface PaletteColorOptions {
    main: string;
    main10?: string;
    main80?: string;
  }
  // add custom background colors here
  // interface TypeBackground {}

  // add additional custom theme params with fixed colors here
  // interface PaletteOptions {}
}

const createAppTheme = (mixin: ThemeOptions) => {
  return createTheme({
    ...mixin,
    // add global overrides here
    typography: {
      htmlFontSize: 18,
      fontFamily: ["Nunito Sans", "Roboto", "Ubuntu"].join(","),
    },
    components: {
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: "#DCF7E3",
            borderRadius: "8px",
            "& .MuiSvgIcon-root": {
              color: mixin.palette?.text?.primary,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            // color: mixin.palette?.text?.primary,
            fontWeight: "bold",
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            "& .MuiTableCell-root": {
              color: mixin.palette?.primary?.main,
            },
          },
        },
      },
    },
  });
};

const light = createAppTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#FF8200",
      //   main10: "#FF82001A", // 10% opacity from main
      //   main80: "#FF8200CC", // 80% opacity from main
    },
    secondary: {
      main: "#FFF5EB", // might be better as a background color variant
    },
    text: {
      primary: "#2A3440",
      secondary: "#6A6E73",
    },
    success: {
      main: "#60D937",
    },
    // background: {
    //   default: "#FFFFFF",
    // },
    // divider: "#E6E6E6",
    // other custom params with colors here
  },
});

export default light;
