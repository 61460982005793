import type { FC } from "react";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import { Customer } from "../../types/Customer";
import { useTenants } from "../../hooks/api";

import {
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Chip,
  Table,
  TableContainer,
} from "@mui/material";

const Tenants: FC<{ customer: Customer }> = ({ customer }) => {
  const navigate = useNavigate();
  const { data = [] } = useTenants(customer.PartnerId, customer.Id);

  return (
    <TableContainer>
      <Table stickyHeader style={{ tableLayout: "fixed" }}>
        <TableHead>
          <TableRow>
            <TableCell>{"Name"}</TableCell>
            <TableCell>{"State"}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row.Id}
              hover
              sx={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/customers/${row.CustomerId}/${row.PartnerId}/tenants/${row.Id}`
                )
              }
            >
              <TableCell>{row.Name}</TableCell>

              <TableCell>
                {row.IsActive ? (
                  <Chip icon={<DoneIcon />} label={"Active"} />
                ) : (
                  <Chip
                    label={"Inactive"}
                    sx={{
                      backgroundColor: (theme) => theme.palette.divider, // might use theme.palette.background.variant instead
                    }}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Tenants;
