import { useParams, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AppPage from "../layout/AppPage";
import Customers from "./Customers";
import Users from "./Users";
import General from "./General";
import { usePartner } from "../../hooks/api";

import {
  Box,
  CardContent,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

const PartnerDetails = () => {
  const { partnerId = "", tab = "customers" } = useParams();
  const partnerFetch = usePartner(partnerId);
  const navigate = useNavigate();

  return (
    <AppPage
      title={
        <Stack direction={"row"} alignItems={"center"}>
          <IconButton onClick={() => navigate("/partners")}>
            <ArrowBackIosIcon />
          </IconButton>
          {partnerFetch.isLoading ? (
            <Typography>...</Typography>
          ) : (
            <>{`${"Partners"} / ${partnerFetch.data?.Name}`}</>
          )}
        </Stack>
      }
    >
      <CardContent>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            variant="fullWidth"
            value={tab}
            onChange={(_, newValue) => {
              navigate(`/partners/${partnerId}/${newValue}`);
            }}
          >
            <Tab value={"customers"} label={"Customers"} />
            <Tab value={"users"} label={"Users"} />
            <Tab value={"general"} label={"General"} />
          </Tabs>
        </Box>
        <Box m={3} sx={{ height: "100%", overflowY: "auto" }}>
          {tab === "customers" && <Customers />}
          {tab === "users" && <Users />}
          {tab === "general" && <General />}
        </Box>
      </CardContent>
    </AppPage>
  );
};

export default PartnerDetails;
