import { AvailablePartnerLicensesResult } from "../types/AvailablePartnerLicensesResult";
import { AvailableTenantLicensesResult } from "../types/AvailableTenantLicensesResult";
import { Customer } from "../types/Customer";
import { Partner } from "../types/Partner";
import { Tenant } from "../types/Tenant";
import { TenantDetails } from "../types/TenantDetails";
import { User } from "../types/User";
import useFetch from "./useFetch";

export const useAvailablePartnerLicenses = (partnerId: string) =>
  useFetch<AvailablePartnerLicensesResult>(["partners", partnerId, "licenses"]);

export const useAvailableTenantLicenses = (
  partnerId: string,
  customerId: string,
  tenantId: string
) =>
  useFetch<AvailableTenantLicensesResult>([
    "partners",
    partnerId,
    "customers",
    customerId,
    "tenants",
    tenantId,
    "licenses",
  ]);

export const useCustomer = (customerId: string, partnerId: string) =>
  useFetch<Customer>(["partners", partnerId, "customers", customerId]);

export const useCustomers = (partnerId?: string) =>
  useFetch<Customer[]>(
    partnerId ? ["partners", partnerId, "customers"] : ["customers"]
  );

export const useMyPartner = () => useFetch<Partner>(["partners", "me"]);

export const useMyUser = () => useFetch<User>(["users", "me"]);

export const usePartner = (partnerId: string) =>
  useFetch<Partner>(["partners", partnerId]);

export const usePartners = () => useFetch<Partner[]>(["partners"]);

export const useTenantDetails = (
  partnerId: string,
  customerId: string,
  tenantId: string
) =>
  useFetch<TenantDetails>([
    "partners",
    partnerId,
    "customers",
    customerId,
    "tenants",
    tenantId,
  ]);

export const useTenants = (partnerId: string, customerId: string) =>
  useFetch<Tenant[]>([
    "partners",
    partnerId,
    "customers",
    customerId,
    "tenants",
  ]);

export const useUsers = (partnerId?: string) =>
  useFetch<User[]>(partnerId ? ["partners", partnerId, "users"] : ["users"]);
