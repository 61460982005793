import type { FC } from "react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../services/auth";
//import { loginRequest } from "../authConfig";

function ErrorComponent(props: any) {
  return <p>An Error Occurred: {props.error?.errorMessage}</p>;
}

function LoadingComponent() {
  return <p>Authentication in progress...</p>;
}
//TODO: handle silent login with a useEffect if MsalAuthenticationTemplate does not cover that
// most likely we wont need to do so since we don't expect people to be logged in the whole day on this
// example: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md#ssosilent-example
// example: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-react-samples/b2c-sample/src/App.js
const Auth: FC<{ children: React.ReactNode }> = ({ children }) => {
  // prep error and loading components https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md#msalauthenticationtemplate-component

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      errorComponent={ErrorComponent}
      loadingComponent={LoadingComponent}
    >
      {children}
    </MsalAuthenticationTemplate>
  );
};

const AuthProvider: FC<{
  pca: IPublicClientApplication;
  children: React.ReactNode;
}> = ({ children, pca }) => {
  return (
    <MsalProvider instance={pca}>
      <Auth>{children}</Auth>
    </MsalProvider>
  );
};

export default AuthProvider;
