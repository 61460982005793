import { useEffect } from "react";
import { BrowserUtils } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

export default function Logout() {
  const { instance } = useMsal();

  useEffect(() => {
    instance.handleRedirectPromise().then(() =>
      instance.logoutRedirect({
        account: instance.getActiveAccount(),
        onRedirectNavigate: () => !BrowserUtils.isInIframe(),
      })
    );
  }, [instance]);

  return <div>Logout</div>;
}
