import { ReactNode } from "react";
import { Card, CardHeader } from "@mui/material";

const AppPage = ({
  avatar,
  title,
  children,
}: {
  avatar?: JSX.Element & ReactNode;
  title: ReactNode;
  children?: ReactNode;
}) => {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 3,
        borderRadius: 0,
      }}
    >
      <CardHeader
        avatar={avatar}
        title={title}
        titleTypographyProps={{
          variant: "h5",
          display: "flex",
          fontWeight: 800,
          minHeight: (theme) => theme.spacing(5),
          alignItems: "center",
        }}
      />
      {children}
    </Card>
  );
};

export default AppPage;
