import { useState } from "react";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import NoMatch from "./components/routes/404";
import Logout from "./components/routes/Logout";
import Navigation from "./components/layout/Navigation";
import { TextField } from "@mui/material";
import Customers from "./components/customers";
import Partners from "./components/partners";
import Users from "./components/users";
import CustomerDetails from "./components/customerdetails";
import TenantDetails from "./components/tenantdetails";
import { useMsal } from "@azure/msal-react";
import { ZYLINC_PARTNER_ID } from "./constants";
import PartnerDetails from "./components/partnerdetails";
import { AccountInfo } from "@azure/msal-browser";

import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

// Maximally defensive accessor
const getPartnerId = (info?: AccountInfo[]) => {
  if (!(info && info[0] && info[0].idTokenClaims)) return "";

  if (!("extension_PartnerId" in info[0].idTokenClaims))
    return ZYLINC_PARTNER_ID;

  return (info[0].idTokenClaims["extension_PartnerId"] as string).toLowerCase();
};

const App = () => {
  const msal = useMsal();
  const partnerId = getPartnerId(msal.accounts);
  const isAdmin = partnerId === ZYLINC_PARTNER_ID;

  return (
    <Container maxWidth={false} disableGutters>
      <Stack
        sx={{
          height: "100vh",
          "& > *": {
            flex: 1,
          },
        }}
        direction="row"
        alignItems="stretch"
        spacing={0}
      >
        <Router>
          <Navigation partnerId={partnerId}>
            <TextField
              fullWidth
              variant={"standard"}
              value={partnerId}
              disabled
            />
          </Navigation>
          <Routes>
            <Route index element={<Navigate to="/customers" />} />
            <Route path="/*" element={<NoMatch />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/users" element={<Users partnerId={partnerId} />} />
            <Route path="/customers">
              <Route index element={<Customers partnerId={partnerId} />} />
              <Route
                path=":customerId/:partnerId"
                element={<CustomerDetails />}
              ></Route>
              <Route path=":customerId/:partnerId/tenants/:tenantId">
                <Route index element={<Navigate to="products" />} />
                <Route
                  path=":tab"
                  element={<TenantDetails isAdmin={isAdmin} />}
                />
              </Route>
            </Route>
            <Route path="/partners">
              <Route index element={<Partners />} />
              <Route path={":partnerId"}>
                <Route index element={<Navigate to={"customers"} />} />
                <Route path={":tab"} element={<PartnerDetails />} />
              </Route>
            </Route>
          </Routes>
        </Router>
      </Stack>
    </Container>
  );
};

export default App;
