import type { FC } from "react";
import { Stack, TextField, Typography } from "@mui/material";
import { useDebouncedCallback } from "use-debounce";
import { styled } from "@mui/material/styles";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

const StyledTextField = styled(TextField)(({ theme }) => ({
  flex: 1,
  backgroundColor: theme.palette.secondary.main,
  borderRadius: "4px",
  "& .MuiOutlinedInput-root": {
    paddingLeft: 0,
    "& fieldset": {
      border: "none",
    },
    "& .MuiSvgIcon-root": {
      margin: theme.spacing(1),
      color: theme.palette.primary.main,
    },
  },
}));

type SearchFieldProps = {
  onChange: (value: string) => void;
};

// defining it in advanced prevents it from redefining on each render
const SearchField: FC<SearchFieldProps> = ({ onChange, ...rest }) => {
  const debounced = useDebouncedCallback(onChange, 1000);
  return (
    <Stack
      flexDirection={"row"}
      gap={1}
      alignItems="center"
      mt={1}
      mb={3}
      {...rest}
    >
      <StyledTextField
        size="small"
        placeholder={"Search"}
        InputProps={{
          startAdornment: <SearchOutlinedIcon />,
        }}
        onChange={(ev) => debounced(ev.target.value)}
      />
    </Stack>
  );
};

export default SearchField;
