import { ReactNode } from "react";
import TableSkeleton from "./TableSkeleton";
import SearchField from "./SearchField";

import {
  CardContent,
  TableContainer,
  Table,
  Box,
  SxProps,
} from "@mui/material";

const PageTable = ({
  isLoading,
  onSearch,
  onCreate,
  sx,
  children,
}: {
  isLoading: boolean;
  children?: ReactNode[];
  onSearch: (term: string) => any;
  onCreate?: () => any;
  sx?: SxProps;
}) => {
  return (
    <Box
      component={CardContent}
      flex={1}
      display={"flex"}
      flexDirection={"column"}
      overflow={"hidden"}
      sx={sx}
    >
      <SearchField onChange={onSearch} />
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <TableContainer>
          <Table stickyHeader>{children}</Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default PageTable;
