import { useQuery } from "@tanstack/react-query";
import xhr from "../services/xhr";

export const fetcher = async ({ queryKey }: any) => {
  const { data } = await xhr.get(`${queryKey.join("/")}`);
  return data;
};

const useFetch = <T>(queryKey: string[]) =>
  useQuery<T>({
    queryKey,
    queryFn: fetcher,
    retry: 1,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

export default useFetch;
