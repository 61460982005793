import DoneIcon from "@mui/icons-material/Done";
import { Tenant } from "../../types/Tenant";
import { useParams } from "react-router-dom";
import CustomerPanel from "../customerdetails/CustomerPanel";
import { useCustomer, useTenantDetails } from "../../hooks/api";

import {
  Chip,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

const TenantPanel: React.FC<{ tenant: Tenant }> = ({ tenant }) => (
  <Stack direction={"column"} gap={3} width={"400px"}>
    <Typography variant={"h5"} color={"primary.main"}>
      {"Tenant"}
    </Typography>
    <Divider sx={{ marginTop: 1 }} />
    <Table
      sx={{
        "& td": {
          border: "none",
        },
      }}
    >
      <colgroup>
        <col style={{ width: "150px" }} />
        <col />
      </colgroup>
      <TableBody>
        <TableRow>
          <TableCell>
            <Typography fontWeight={1}>{"Tenant name"}</Typography>
          </TableCell>
          <TableCell>
            <Typography fontWeight={700}>{tenant.Name}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography fontWeight={1}>{"Status"}</Typography>
          </TableCell>
          <TableCell>
            {tenant.IsActive ? (
              <Chip icon={<DoneIcon />} label={"Active"} />
            ) : (
              <Chip
                label={"Inactive"}
                sx={{
                  backgroundColor: (theme) => theme.palette.divider, // might use theme.palette.background.variant instead
                }}
              />
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography fontWeight={1}>{"Note"}</Typography>
          </TableCell>
          <TableCell>
            <Typography fontWeight={700}>{tenant.Comment}</Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Stack>
);

const Details = () => {
  const { customerId = "", tenantId = "", partnerId = "" } = useParams();
  const customerHook = useCustomer(customerId, partnerId);
  const tenantHook = useTenantDetails(partnerId, customerId, tenantId);

  return (
    <Stack direction={"row"} marginTop={3}>
      {customerHook.data ? (
        <CustomerPanel customer={customerHook.data} />
      ) : (
        <div style={{ width: "600px" }} />
      )}
      <Stack flex={1} direction={"column"} gap={3}>
        {tenantHook.data && <TenantPanel tenant={tenantHook.data} />}
      </Stack>
    </Stack>
  );
};

export default Details;
