import { useParams, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AppPage from "../layout/AppPage";
import Tenants from "./Tenants";
import CustomerPanel from "./CustomerPanel";
import { useCustomer } from "../../hooks/api";

import {
  CardContent,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

const CustomerDetails = () => {
  const { customerId = "", partnerId = "" } = useParams();
  const { data } = useCustomer(customerId, partnerId);
  const navigate = useNavigate();

  if (!data) return <>Loading...</>;

  return (
    <AppPage
      title={
        <Stack direction={"row"} alignItems={"center"}>
          <IconButton onClick={() => navigate("/customers")}>
            <ArrowBackIosIcon />
          </IconButton>
          {`Customers / ${data.Name}`}
        </Stack>
      }
    >
      <CardContent
        sx={{
          overflow: "hidden",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Stack
          direction={"column"}
          gap={3}
          width={"400px"}
          marginRight={"200px"}
        >
          <CustomerPanel customer={data} />
        </Stack>
        <Stack flex={1} direction={"column"} gap={3}>
          <Typography variant={"h5"} color={"primary.main"}>
            {"Tenants"}
          </Typography>
          <Divider />
          <Tenants customer={data} />
        </Stack>
      </CardContent>
    </AppPage>
  );
};

export default CustomerDetails;
