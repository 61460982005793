import { useParams, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AppPage from "../layout/AppPage";
import Details from "./Details";
import Products from "./Products";
import { useTenantDetails } from "../../hooks/api";
import { Box, CardContent, IconButton, Stack, Tab, Tabs } from "@mui/material";

const TenantDetails = ({ isAdmin }: { isAdmin: boolean }) => {
  const {
    customerId = "",
    tenantId = "",
    partnerId = "",
    tab = "details",
  } = useParams();

  const tenantDetails = useTenantDetails(partnerId, customerId, tenantId).data;
  const navigate = useNavigate();

  if (!tenantDetails) return <>Loading...</>;

  return (
    <AppPage
      title={
        <Stack direction={"row"} alignItems={"center"}>
          <IconButton
            onClick={() => navigate(`/customers/${customerId}/${partnerId}`)}
          >
            <ArrowBackIosIcon />
          </IconButton>
          {`Customers / ... / ${tenantDetails.Name}`}
        </Stack>
      }
    >
      <CardContent>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            variant="fullWidth"
            value={tab}
            onChange={(_, newValue) => {
              navigate(
                `/customers/${customerId}/${partnerId}/tenants/${tenantId}/${newValue}`
              );
            }}
          >
            <Tab value={"details"} label={"Tenant details"} />
            <Tab value={"products"} label={"Tenant products"} />
          </Tabs>
        </Box>
        <Box m={3} sx={{ height: "100%", overflowY: "auto" }}>
          {tab === "details" && <Details />}
          {tab === "products" && <Products isAdmin={isAdmin} />}
        </Box>
      </CardContent>
    </AppPage>
  );
};

export default TenantDetails;
