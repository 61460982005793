import { useQueries } from "@tanstack/react-query";
import { fetcher } from "./useFetch";
import { AvailableTenantLicensesResult } from "../types/AvailableTenantLicensesResult";
import { TenantDetails } from "../types/TenantDetails";
import { LicenseProfile, QuotaLicense, TenantFeature } from "../types/License";

export type ProductsFormData = {
  TenantSize: QuotaLicense;
  LicenseProfiles: LicenseProfile[];
  QuotaLicenses: QuotaLicense[];
  TenantFeatures: (TenantFeature & { HasLicense?: boolean })[];
};

const getData = (
  details: TenantDetails,
  licenses: AvailableTenantLicensesResult
) => ({
  TenantSize: details.TenantSize,
  QuotaLicenses: licenses.QuotaLicenses.map(
    (info: any) =>
      details.QuotaLicenses.find(({ Id }) => Id === info.Id) || {
        ...info,
        Quota: 0,
      }
  ),
  LicenseProfiles: licenses.LicenseProfiles.map(
    (info: any) =>
      details.LicenseProfiles.find(({ Id }) => Id === info.Id) || {
        ...info,
        MaxSessions: 0,
      }
  ),
  TenantFeatures: licenses.FeatureLicenses.map((info) => ({
    ...info,
    HasLicense: Boolean(
      details.FeatureLicenses.find(({ Id }) => Id === info.Id)
    ),
  })),
});

const useProductsFormData = (
  partnerId: string,
  customerId: string,
  tenantId: string
): {
  data: ProductsFormData | null;
  refetch: () => any;
  isFetching: boolean;
} => {
  const baseKey = [
    "partners",
    partnerId,
    "customers",
    customerId,
    "tenants",
    tenantId,
  ];

  const [details, licenses] = useQueries({
    queries: [
      { queryKey: baseKey, queryFn: fetcher },
      { queryKey: [...baseKey, "licenses"], queryFn: fetcher },
    ],
  });

  return {
    data:
      details.data && licenses.data
        ? getData(details.data, licenses.data)
        : null,
    refetch: () => Promise.all([details.refetch(), licenses.refetch()]),
    isFetching: details.isFetching || licenses.isFetching,
  };
};

export default useProductsFormData;
