import { BrowserCacheLocation, LogLevel } from "@azure/msal-browser";

const AUTH_DEBUG = false;

export const msalConfig = {
  auth: {
    clientId: "", // Will be overwritten in ./services/auth
    authority: "", // Will be overwritten in ./services/auth
    knownAuthorities: [] as string[], // Will be overwritten in ./services/auth
    redirectUri: "/", // Will be overwritten in ./services/auth
    postLogoutRedirectUri: "/", // Will be overwritten in ./services/auth
    // navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    // cacheLocation: BrowserCacheLocation.SessionStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    // allowNativeBroker: false, // Disables WAM Broker
    loggerOptions: {
      logLevel: LogLevel.Trace,
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ) => {
        if (containsPii) {
          return;
        }
        if (!AUTH_DEBUG) return;
        switch (level) {
          case LogLevel.Trace:
            console.error(message);
            return;
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

/*
export const loginRequest: PopupRequest = {
  scopes: [
    "https://zylincaccountportal.onmicrosoft.com/5baf768e-b140-4e67-88cf-aea193c13429/access_as_user",
  ],
};
*/
