import { usePartner } from "../../hooks/api";
import { Customer } from "../../types/Customer";

import {
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

const CustomerPanel: React.FC<{ customer: Customer }> = ({ customer }) => {
  const partner = usePartner(customer.PartnerId);

  return (
    <Stack direction={"column"} gap={3} width={"400px"} marginRight={"200px"}>
      <Typography variant={"h5"} color={"primary.main"}>
        {"Customer"}
      </Typography>
      <Divider />
      <Table
        sx={{
          "& td": {
            border: "none",
          },
        }}
      >
        <colgroup>
          <col style={{ width: "150px" }} />
          <col />
        </colgroup>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography fontWeight={1}>{"Customer name"}</Typography>
            </TableCell>
            <TableCell>
              <Typography fontWeight={700}>{customer.Name}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography fontWeight={1}>{"Partner"}</Typography>
            </TableCell>
            <TableCell>
              <Typography fontWeight={700}>
                {partner.data?.Name || "No partner"}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography fontWeight={1}>{"Note"}</Typography>
            </TableCell>
            <TableCell>
              <Typography fontWeight={700}>{customer.Comment}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  );
};

export default CustomerPanel;
