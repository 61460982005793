import { useNavigate, useParams } from "react-router-dom";
import { useCustomers } from "../../hooks/api";

import {
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableContainer,
} from "@mui/material";

const Customers = () => {
  const navigate = useNavigate();
  const { partnerId = "" } = useParams();
  const { data = [] } = useCustomers(partnerId);

  return (
    <TableContainer>
      <Table stickyHeader style={{ tableLayout: "fixed" }}>
        <colgroup>
          <col style={{ width: "600px" }} />
          <col />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>{"Name"}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row.Id}
              hover
              sx={{ cursor: "pointer" }}
              onClick={() => navigate(`/customers/${row.Id}/${row.PartnerId}`)}
            >
              <TableCell>{row.Name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Customers;
