import axios from "axios";

const xhr = axios.create({
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Origin": `*`,
  },
});

export default xhr;
