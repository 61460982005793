import { useParams } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import { useUsers } from "../../hooks/api";

import {
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableContainer,
  Chip,
} from "@mui/material";

const Users = () => {
  const { partnerId = "" } = useParams();
  const { data = [] } = useUsers(partnerId);

  return (
    <TableContainer>
      <Table stickyHeader style={{ tableLayout: "fixed" }}>
        <colgroup>
          <col style={{ width: "600px" }} />
          <col />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>{"Name"}</TableCell>
            <TableCell>{"Email"}</TableCell>
            <TableCell>{"Status"}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.Id}>
              <TableCell>{row.DisplayName}</TableCell>
              <TableCell>{row.Email}</TableCell>
              <TableCell>
                {row.IsActive ? (
                  <Chip icon={<DoneIcon />} label={"Active"} />
                ) : (
                  <Chip
                    label={"Inactive"}
                    sx={{
                      backgroundColor: (theme) => theme.palette.divider, // might use theme.palette.background.variant instead
                    }}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Users;
